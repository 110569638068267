import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from './pages/HomePage';
import PlantPage from './pages/Plant/index';
import ProfilePage from './pages/Profile/index';
import ErrorPage from './error';
const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
    errorElement: <ErrorPage title="Error" />,
  },
  {
    path: 'plant/:id',
    element: <PlantPage title="Plant" />,
    errorElement: <ErrorPage />,
    //loader: eventLoader,
  },
  {
    path: 'profile/:id',
    element: <ProfilePage title="Profile" />,
    errorElement: <ErrorPage />,
    //loader: eventLoader,
  },
]);
export default function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}
