// Library imports
import React, { useEffect, useState } from "react";
import { useParams, Link, useSearchParams } from "react-router-dom";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  getCount,
  query,
  orderBy,
  limit,
} from "firebase/firestore/lite";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

// Component imports

import { db } from "../../Firebase";
import NoData from "../../components/nodata";
import Footer from "../../components/Footer";

const Profile = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const businessName = searchParams.get("businessName");
  let { id } = useParams();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [collectionCount, setCollectionCount] = useState(0);
  const [plantCount, setPlantCount] = useState(0);
  const [PlantData, setPlantData] = useState(0);
  const [collectionData, setCollectionData] = useState(0);
  const [userUrl, setUserUrl] = useState();

  const getPlantData = async (userId) => {
    const plantCount = await getCount(
      query(collection(db, "users", userId, "plants"))
    );
    const count = plantCount?.data()?.count ?? 0;
    setPlantCount(count);

    const q = query(
      collection(db, "users", userId, "plants"),
      orderBy("createdAt", "desc"),
      limit(2)
    );

    const querySnapshot = await getDocs(q);
    var tempPlants = [];
    querySnapshot.forEach((doc) => {
      tempPlants.push(doc.data());
    });
    return tempPlants;
  };

  const getCollectionPlantCount = async (userId, plantdocId) => {
    const collectionCount = await getCount(
      query(
        collection(db, "users", userId, "myCollections", plantdocId, "plants")
      )
    );
    const count = (await collectionCount?.data()?.count) ?? 0;
    return count;
  };

  const getCollectionData = async (userId) => {
    const collectionCount = await getCount(
      query(collection(db, "users", userId, "myCollections"))
    );
    const count = collectionCount?.data()?.count ?? 0;
    setCollectionCount(count);

    const q = query(
      collection(db, "users", userId, "myCollections"),
      orderBy("createdAt", "desc"),
      limit(2)
    );
    const querySnapshot = await getDocs(q);
    var tempCollection = [];
    querySnapshot.forEach(async (doc) => {
      const querySnapshotData = doc.data();
      const plantCount = await getCollectionPlantCount(
        userId,
        querySnapshotData.id
      );
      tempCollection.push({ ...querySnapshotData, plantCount: plantCount });
    });
    return tempCollection;
  };

  useEffect(() => (document.title = props?.title), []);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const docRef = doc(db, "users", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setUser(docSnap.data());
        const userId = docSnap.data()?.userID;
        setUserUrl(`https://odlamer.app.link/`);
        const CollectionData = await getCollectionData(userId);
        const plantData = await getPlantData(userId);
        setCollectionData(CollectionData);
        setPlantData(plantData);
      } else {
        console.log("No such document!");
      }
      setLoading(false);
    }
    fetchData();
    return () => fetchData();
  }, [id]);

  const MailtoExpert = ({ email, subject = "", body = "", children }) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  const renderLoading = () => (
    <div className="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
      <div className="border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-8 h-24 w-24"></div>

      <p className="text-gray text-lg mt-5">Loading Data ...</p>
    </div>
  );

  const renderProfileInfo = () => (
    <div>
      {Object.keys(user).length === 0 ? (
        <NoData type={"profile"} />
      ) : (
        <>
          <Carousel
            showThumbs={false}
            showArrows
            useKeyboardArrows
            showStatus={false}
            // autoPlay
            showIndicators={false}
            autoFocus
            swipeable
            axis="horizontal"
            className="relative"
            animationHandler={"slide"}
            renderArrowPrev={(onClickHandler, hasPrev) =>
              hasPrev && (
                <button
                  className="rounded-full bg-gray p-1 absolute z-20 top-1/2 left-0 transform -translate-y-1/2 ml-4"
                  onClick={onClickHandler}
                >
                  <ChevronLeftIcon
                    color="#FFFFFF"
                    strokeWidth={2}
                    className="md:h-7 md:w-7 sm:h-6 sm:w-6 h-6 w-6 lg:h-8 lg:w-8"
                  />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext) =>
              hasNext && (
                <button
                  className="rounded-full bg-gray p-1 absolute z-2 top-1/2 right-0 transform -translate-y-1/2 mr-4"
                  onClick={onClickHandler}
                >
                  <ChevronRightIcon
                    color="#FFFFFF"
                    strokeWidth={2}
                    className="md:h-7 md:w-7 sm:h-6 sm:w-6 h-6 w-6 lg:h-8 lg:w-8"
                  />
                </button>
              )
            }
          >
            <div
              style={{
                backgroundImage: `url(/images/profile_template.png)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={user?.profilePictureURL}
                alt="profile"
                className="h-3/5 max-w-350 rounded-full"
              />

              {businessName === "Hornbach" && (
                <div className="absolute top-0 right-0 p-2">
                  <p className="text-white text-xs text-left md:mx-2 md:mb-1 mb-1">
                    Currently available at
                  </p>
                  <img
                    className="h-9 max-w-120"
                    src={"/icons/hornbach_logo.png"}
                    alt="hornbach_logot"
                  />
                </div>
              )}
              <div className="bottom-2 left-0 p-6">
                <p className="text-white text-2xl font-bold text-center">
                  {user?.username}
                </p>
                <p className="text-white text-base font-normal text-center italic">
                  {user?.subRegion}
                </p>
              </div>
            </div>
          </Carousel>
          <div className="flex flex-col space-y-6 mt-10">
            <div className="flex">
              <p className="text-black text-2xl font-sfpro mx-8 font-bold">
                {t(
                  `Explore my plant collection and become a part of our thriving plant lovers' community on the Odlamer app.`
                )}
              </p>
            </div>
          </div>

          <div className="bg-light-green h-0.5 m-8" />

          <div className="flex flex-col justify-center items-center mt-5">
            <p className="text-gray text-base font-sfpro ml-5 font-medium">
              {t("Have any suggestions or questions about the App?")}
            </p>

            <MailtoExpert
              email="experts@odlamer.com"
              subject="Suggestion to improve the app"
              body={`User Id: ${user?.id}\nUser Name: ${user?.username}`}
            >
              <p className="text-gray font-bold text-base font-sfpro ml-5 underline">
                {t("Click here to reach out to our team.")}
              </p>
            </MailtoExpert>
          </div>

          <div className="flex flex-col md:flex-row m-8 gap-8 justify-between">
            <div className="flex flex-col md:w-1/2">
              <p className="text-black text-2xl font-sfpro font-bold max-w-350">
                {t("The Odla Mer app is now available")}{" "}
                <span className="text-gray">
                  {t("for download on both iOS and Android")}
                </span>
              </p>
              <p className="text-gray text-md font-sfpro font-normal my-4 max-w-350">
                {t(
                  "In 🇸🇪🇳🇴🇫🇮 🇬🇧 🇳🇱, we are live on Apple app store and Google play store. Just now we are offering 60 days free premium trail to all our new users!"
                )}
              </p>
            </div>

            <div className="flex flex-row items-center gap-2">
              <div className="flex flex-col justify-center">
                {/* <p className="text-gray text-sm font-sfpro font-medium pb-3">
                  for IOS
                </p> */}
                <Link
                  to={"https://apps.apple.com/se/app/odla-mer/id1611168111"}
                  target="_blank"
                >
                  <img
                    className="object-cover h-10"
                    src={"/icons/app_store_icon.svg"}
                    alt="apple_store_badge"
                  />
                </Link>
              </div>
              <div className="flex flex-col justify-center">
                {/* <p className="text-gray text-sm font-sfpro font-medium pl-3 pb-3">
                  for Android
                </p> */}
                <Link
                  to={
                    "https://play.google.com/store/apps/details?id=com.odlamer.odlamerapp"
                  }
                  target="_blank"
                >
                  <img
                    className="object-cover h-10"
                    width={150}
                    src={"/icons/google-play-badge.png"}
                    alt="play_store_badge"
                  />
                </Link>
              </div>
            </div>
          </div>

          <Footer businessName={businessName} />
        </>
      )}
    </div>
  );

  return (
    <div className="flex">
      <div className="lg:w-6/12 md:w-8/12 sm:w-10/12 xs:w-12/12 mx-auto  shadow-md overflow-hidden md:mt-10 sm:mt-5 xs:mt-2">
        {loading ? renderLoading() : renderProfileInfo()}
      </div>
    </div>
  );
};

export default Profile;
