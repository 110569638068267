import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importing translation files
import sv from './languages/sv.json';
import en from './languages/en.json';
import es from './languages/es.json';
import nl from './languages/nl.json';

//Creating object with the variables of imported translation files
export const locales = {
  en: {
    translation: en,
  },
  sv: {
    translation: sv,
  },
  es: {
    translation: es,
  },
  nl: {
    translation: nl,
  },
};

//i18N Initialization

i18n.use(initReactI18next).init({
  resources: locales,
  lng: 'sv', // Default language
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
