import { Link } from "react-router-dom";

const NoData = (props) => {
  const { type } = props;
  return (
    <div className="mt-20 mx-10 md:mx-0">
      <h1 className="text-center text-black font-bold text-lg md:text-3xl font-sfpro">
        {type === "plant"
          ? "Sorry, we couldn’t find the plant."
          : "Sorry, we couldn’t find the user."}
        <p>{process.env.REACT_APP_AUTH_DOMAIN}</p>
      </h1>
      <h1 className="text-center text-gray font-bold text-lg md:text-3xl font-sfpro mt-2">
        Panda must have eaten it 🐼
      </h1>
      <div className="flex justify-center">
        <video
          autoPlay="autoplay"
          playsInline="playsInline"
          loop="true"
          muted="true"
          src="/404.mp4"
        ></video>
      </div>
      <p className=" flex flex-row  justify-center text-center text-gray font-normal text-md md:text-xl font-sfpro">
        Email us at
        <Link
          to="#"
          target="_blank"
          onClick={(e) => {
            window.location = "mailto:yourmail@domain.com";
            e.preventDefault();
          }}
        >
          <p className="underline md:mx-1">hello@odlamer.com</p>
        </Link>
        to report the issue.{" "}
      </p>
    </div>
  );
};

export default NoData;
