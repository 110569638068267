// Library imports
import React, { useEffect, useState } from "react";
import { useParams, Link, useSearchParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore/lite";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  BookmarkIcon,
  GiftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";

// Component imports
import { db } from "../../Firebase";
import NoData from "../../components/nodata";
import Footer from "../../components/Footer";

const Plant = (props) => {
  const { id } = useParams();
  const [plant, setPlant] = useState({});
  const [loading, setLoading] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const businessName = searchParams.get("businessName");
  const fetchData = async () => {
    setLoading(true);
    try {
      const plantRef = doc(db, "plants_master", id);
      const plantSnap = await getDoc(plantRef);

      if (plantSnap.exists()) {
        const plantData = plantSnap.data();
        const fetchedPlant = plantData;
        fetchedPlant.media = fetchedPlant.media.filter(
          (item) => !item.url.includes("150x150")
        );
        setPlant(fetchedPlant);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    document.title = props?.title;
    fetchData();
  }, [id]);

  const MailtoExpert = ({ email, subject = "", body = "", children }) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  const renderLoading = () => (
    <div className="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
      <div className="border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-8 h-24 w-24"></div>

      <p className="text-gray text-lg mt-5">Loading Data ...</p>
    </div>
  );

  const renderPlantInfo = () => (
    <div>
      {Object.keys(plant).length === 0 ? (
        <NoData type={"plant"} />
      ) : (
        <>
          <Carousel
            showThumbs={false}
            showArrows
            useKeyboardArrows
            showStatus={false}
            // autoPlay
            autoFocus
            swipeable
            axis="horizontal"
            className="m-8 relative"
            animationHandler={"slide"}
            renderArrowPrev={(onClickHandler, hasPrev) =>
              hasPrev && (
                <button
                  className="rounded-full bg-gray p-1 absolute z-20 top-1/2 left-0 transform -translate-y-1/2 ml-4"
                  onClick={onClickHandler}
                >
                  <ChevronLeftIcon
                    color="#FFFFFF"
                    strokeWidth={2}
                    className="md:h-7 md:w-7 sm:h-6 sm:w-6 h-6 w-6 lg:h-8 lg:w-8"
                  />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext) =>
              hasNext && (
                <button
                  className="rounded-full bg-gray p-1 absolute z-2 top-1/2 right-0 transform -translate-y-1/2 mr-4"
                  onClick={onClickHandler}
                >
                  <ChevronRightIcon
                    color="#FFFFFF"
                    strokeWidth={2}
                    className="md:h-7 md:w-7 sm:h-6 sm:w-6 h-6 w-6 lg:h-8 lg:w-8"
                  />
                </button>
              )
            }
          >
            {plant?.media &&
              plant?.media.length > 0 &&
              plant?.media.map((plantImage, index) => (
                <div className="relative bg-black" key={index}>
                  <img
                    src={plantImage.url}
                    alt="plant"
                    className="h-full max-w-350 object-contain"
                  />
                  <div className="absolute inset-0 flex flex-row items-end justify-end gap-4 my-3 mx-6">
                    <button className="rounded-full bg-lime p-2">
                      <BookmarkIcon
                        color="#229449"
                        strokeWidth={2}
                        className="h-6 w-6"
                      />
                    </button>
                    <button className="rounded-full bg-lime p-2">
                      <GiftIcon
                        color="#229449"
                        strokeWidth={2}
                        className="h-6 w-6"
                      />
                    </button>
                  </div>
                  {businessName === "Hornbach" && (
                    <div className="absolute top-0 right-0 p-2">
                      <p className="text-white text-xs text-left md:mx-2 md:mb-1 mb-1">
                        Currently available at
                      </p>
                      <img
                        className="h-9 max-w-120"
                        src={"/icons/hornbach_logo.png"}
                        alt="hornbach_logot"
                      />
                    </div>
                  )}
                  <div className="absolute bottom-2 left-0 p-6">
                    <p className="text-white text-2xl font-bold text-left">
                      {plant?.plantName}
                    </p>
                    <p className="text-white text-base font-normal text-left italic">
                      {plant?.scientificName}
                    </p>
                  </div>
                </div>
              ))}
          </Carousel>
          <div className="flex flex-col space-y-6 mt-10">
            <div className="flex"></div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 m-8">
              <div className="flex flex-col gap-2">
                <h2 className="text-2xl font-bold mb-2">
                  {t("Plant details")}
                </h2>
                <div className="bg-light-gray py-4 rounded-xl border border-gray-300">
                  <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2">
                    {plant?.levelOfCare && plant?.levelOfCare !== "" && (
                      <div className="flex flex-col items-center">
                        <img
                          className="object-cover h-8 w-8"
                          src={"/icons/care_level.png"}
                          alt="care_level"
                        />
                        <p className="text-gray text-sm font-sfpro font-bold mt-2">
                          {t("Care Level")}
                        </p>
                        <p className="text-gray text-sm font-sfpro font-normal">
                          {plant?.levelOfCare}
                        </p>
                      </div>
                    )}
                    {plant?.hardiness && plant?.hardiness !== "" && (
                      <div className="flex flex-col items-center">
                        <img
                          className="object-cover h-8 w-8"
                          src={"/icons/hardiness.png"}
                          alt="hardiness"
                        />
                        <p className="text-gray text-sm font-sfpro font-bold mt-2">
                          {t("Hardiness")}
                        </p>
                        <p className="text-gray text-sm font-sfpro font-normal">
                          {plant?.hardiness}
                        </p>
                      </div>
                    )}
                    {plant?.position && plant?.position.length > 0 && (
                      <div className="flex flex-col items-center">
                        <img
                          className="object-cover h-8 w-8"
                          src={"/icons/position.png"}
                          alt="position"
                        />
                        <p className="text-gray text-sm font-sfpro font-bold mt-2">
                          {t("Position")}
                        </p>
                        <p className="text-gray text-sm font-sfpro font-normal">
                          {plant?.position.join(",")}
                        </p>
                      </div>
                    )}

                    {plant?.watering?.wateringNeeds &&
                      plant?.watering?.wateringNeeds !== "" && (
                        <div className="flex flex-col items-center">
                          <img
                            className="object-cover h-8 w-8"
                            src={"/icons/watering_needs.png"}
                            alt="watering_needs"
                          />
                          <p className="text-gray text-sm font-sfpro font-bold mt-2">
                            {t("Watering Needs")}
                          </p>
                          <p className="text-gray text-sm font-sfpro font-normal">
                            {plant?.watering?.wateringNeeds}
                          </p>
                        </div>
                      )}

                    {plant?.colorOfLeaves && plant?.colorOfLeaves !== "" && (
                      <div className="flex flex-col items-center">
                        <img
                          className="object-cover h-8 w-8"
                          src={"/icons/leaf_color.png"}
                          alt="leaf_color"
                        />
                        <p className="text-gray text-sm font-sfpro font-bold mt-2">
                          {t("Leaf Colors")}
                        </p>
                        <p className="text-gray text-sm font-sfpro font-normal">
                          {plant?.colorOfLeaves}
                        </p>
                      </div>
                    )}

                    {plant?.flowerColors && plant?.flowerColors !== "" && (
                      <div className="flex flex-col items-center">
                        <img
                          className="object-cover h-8 w-8"
                          src={"/icons/flower_color.png"}
                          alt="flower_color"
                        />
                        <p className="text-gray text-sm font-sfpro font-bold mt-2">
                          {t("Flower Colors")}
                        </p>
                        <p className="text-gray text-sm font-sfpro font-normal">
                          {plant?.flowerColors}
                        </p>
                      </div>
                    )}

                    {plant?.bloomOrHarvestPeriod &&
                      plant?.bloomOrHarvestPeriod.length > 0 && (
                        <div className="flex flex-col items-center">
                          <img
                            className="object-cover h-8 w-8"
                            src={"/icons/bloom_period.png"}
                            alt="bloom_period"
                          />
                          <p className="text-gray text-sm font-sfpro font-bold mt-2">
                            {t("Bloom/Harvest Period")}
                          </p>

                          <p className="text-gray text-sm font-sfpro font-normal">
                            {plant?.bloomOrHarvestPeriod &&
                              plant?.bloomOrHarvestPeriod.length > 0 &&
                              `${plant?.bloomOrHarvestPeriod[0]} - ${
                                plant?.bloomOrHarvestPeriod[
                                  plant?.bloomOrHarvestPeriod.length - 1
                                ]
                              }`}
                          </p>
                        </div>
                      )}

                    {plant?.plantGroup && plant?.plantGroup !== "" && (
                      <div className="flex flex-col items-center">
                        <img
                          className="object-cover h-8 w-8"
                          src={"/icons/plant_group.png"}
                          alt="plant_group"
                        />
                        <p className="text-gray text-sm font-sfpro font-bold mt-2">
                          {t("Plant Group")}
                        </p>
                        <p className="text-gray text-sm font-sfpro font-normal">
                          {plant?.plantGroup}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <h2 className="text-2xl font-bold mb-2">
                  {t("Description about the plant")}
                </h2>
                <div className="bg-light-gray p-4 rounded-xl border border-gray-300">
                  <p className="text-gray text-base font-sfpro font-normal">
                    {plant?.description}
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-2 ">
                <h2 className="text-2xl font-bold mb-2">
                  {t("Additional information")}
                </h2>
                {/* <div className="bg-light-gray px-4 py-1 rounded-xl border border-gray-300"> */}
                <div className="grid grid-cols-1 gap-2 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1  ">
                  {!_.isEmpty(plant?.plantType) && (
                    <div className="bg-light-gray px-4 py-2 rounded-xl border border-gray-300">
                      <div className="flex flex-row gap-1">
                        <div className="flex flex-col">
                          <img
                            className="object-cover h-5 w-5"
                            src={"/icons/plant_type_icon.png"}
                            alt="plant_type_icon"
                          />
                        </div>
                        <div className="flex flex-col">
                          <p className="font-bold text-sm">{t("Plant type")}</p>

                          <p className="text-sm text-gray">
                            {typeof plant?.plantType === "string"
                              ? plant?.plantType
                              : plant?.plantType.join(", ")}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {plant?.bloomOrHarvestPeriod &&
                    plant?.bloomOrHarvestPeriod.length > 0 && (
                      <div className="bg-light-gray px-4 py-2 rounded-xl border border-gray-300">
                        <div className="flex flex-row gap-1 ">
                          <div className="flex flex-col">
                            <img
                              className="object-cover h-5 w-5"
                              src={"/icons/bloom_period_icon.png"}
                              alt="bloom_period_icon"
                            />
                          </div>
                          <div className="flex flex-col">
                            <p className="font-semibold text-sm">
                              {t("Bloom /harvest period")}
                            </p>
                            <p className="text-sm text-gray">
                              {plant?.bloomOrHarvestPeriod &&
                                plant?.bloomOrHarvestPeriod.length > 0 &&
                                `${plant?.bloomOrHarvestPeriod[0]} - ${
                                  plant?.bloomOrHarvestPeriod[
                                    plant?.bloomOrHarvestPeriod.length - 1
                                  ]
                                }`}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                  {plant?.fertilizerType && plant?.fertilizerType !== "" && (
                    <div className="bg-light-gray px-4 py-2 rounded-xl border border-gray-300">
                      <div className="flex flex-row gap-1 ">
                        <div className="flex flex-col">
                          <img
                            className="object-cover h-5 w-5"
                            src={"/icons/fertilizer_icon.png"}
                            alt="fertilizer_icon"
                          />
                        </div>
                        <div className="flex flex-col">
                          <p className="font-semibold text-sm">
                            {t("Fertilizer")}
                          </p>

                          <p className="text-sm text-gray">
                            {plant?.fertilizerType}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {plant?.soilType && plant?.soilType !== "" && (
                    <div className="bg-light-gray px-4 py-2 rounded-xl border border-gray-300">
                      <div className="flex flex-row gap-1 ">
                        <div className="flex flex-col">
                          <img
                            className="object-cover h-5 w-5"
                            src={"/icons/soil_type_icon.png"}
                            alt="soil_type_icon"
                          />
                        </div>
                        <div className="flex flex-col">
                          <p className="font-semibold text-sm">
                            {t("Soil type")}
                          </p>

                          <p className="text-sm text-gray">{plant?.soilType}</p>
                        </div>
                      </div>
                    </div>
                  )}

                  {(plant?.rowDistance && plant?.rowDistance !== "") ||
                    (plant?.plantDistance && plant?.plantDistance !== "" && (
                      <div className="bg-light-gray px-4 py-2 rounded-xl border border-gray-300">
                        <div className="flex flex-row gap-1 ">
                          <div className="flex flex-col">
                            <img
                              className="object-cover h-5 w-5"
                              src={"/icons/planting_outdoor_icon.png"}
                              alt="playStore"
                            />
                          </div>
                          <div className="flex flex-col">
                            <p className="font-semibold text-sm ">
                              {t("Planting outdoors")}
                            </p>
                            {plant?.rowDistance &&
                              plant?.rowDistance !== "" && (
                                <p className="text-sm text-gray">
                                  {t("Row distance")} - {plant?.rowDistance}
                                  cm
                                </p>
                              )}
                            {plant?.plantDistance &&
                              plant?.plantDistance !== "" && (
                                <p className="text-sm text-gray">
                                  {t("Planting distance")} -{" "}
                                  {plant?.plantDistance} cm
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    ))}

                  {(!_.isEmpty(plant?.sowingDepth) ||
                    !_.isEmpty(plant?.needsHardening)) && (
                    <div className="bg-light-gray px-4 py-2 rounded-xl border border-gray-300">
                      <div className="flex flex-row gap-1 ">
                        <div className="flex flex-col">
                          <img
                            className="object-cover h-5 w-5"
                            src={"/icons/sowing_icon.png"}
                            alt="sowing_icon"
                          />
                        </div>
                        <div className="flex flex-col">
                          <p className="font-semibold text-sm">{t("Sowing")}</p>
                          {!_.isEmpty(plant?.sowingDepth) && (
                            <p className="text-sm text-gray">
                              {t("Sowing depth")} - {plant?.sowingDepth} cm
                            </p>
                          )}
                          {/* {plant?.location && plant?.location !== '' && (
                              <p className="text-sm text-gray">
                                {t('Sowing location')} - {plant?.location}
                              </p>
                            )} */}
                          {!_.isEmpty(plant?.needsHardening) && (
                            <p className="text-sm text-gray">
                              {t("Hardening off")} -{" "}
                              {plant?.needsHardening
                                ? t("Needed")
                                : t("Not Needed")}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {plant?.heightMax && (
                    <div className="bg-light-gray px-4 py-2 rounded-xl border border-gray-300">
                      <div className="flex flex-row gap-1 ">
                        <div className="flex flex-col">
                          <img
                            className="object-cover h-5 w-5"
                            src={"/icons/height_icon.png"}
                            alt="heightMax_icon"
                          />
                        </div>
                        <div className="flex flex-col">
                          <p className="font-semibold text-sm">{t("Height")}</p>

                          <p className="text-sm text-gray">
                            {plant?.heightMax} cm
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {plant?.suitableForContainers && (
                    <div className="bg-light-gray px-4 py-2 rounded-xl border border-gray-300">
                      <div className="flex flex-row gap-1 ">
                        <div className="flex flex-col">
                          <img
                            className="object-cover h-5 w-5"
                            src={"/icons/suitable_for_containers_icon.png"}
                            alt="suitableForContainers_icon"
                          />
                        </div>
                        <div className="flex flex-col">
                          <p className="font-semibold text-sm">
                            {t("Suitable for containers")}
                          </p>

                          <p className="text-sm text-gray">
                            {plant?.suitableForContainers ? t("Yes") : t("No")}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {plant?.suitableCutFlowers && (
                    <div className="bg-light-gray px-4 py-2 rounded-xl border border-gray-300">
                      <div className="flex flex-row gap-1 ">
                        <div className="flex flex-col">
                          <img
                            className="object-cover h-5 w-5"
                            src={"/icons/suitable_as_cutflowers_icon.png"}
                            alt="suitableCutFlowers"
                          />
                        </div>
                        <div className="flex flex-col">
                          <p className="font-semibold text-sm">
                            {t("Suitable as cut flowers")}
                          </p>

                          <p className="text-sm text-gray">
                            {plant?.suitableCutFlowers ? t("Yes") : t("No")}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {!_.isEmpty(plant?.scentTypes) && (
                    <div className="bg-light-gray px-4 py-2 rounded-xl border border-gray-300">
                      <div className="flex flex-row gap-1 ">
                        <div className="flex flex-col">
                          <img
                            className="object-cover h-5 w-5"
                            src={"/icons/scent_icon.png"}
                            alt="scent_icon"
                          />
                        </div>
                        <div className="flex flex-col">
                          <p className="font-semibold text-sm">{t("Scent")}</p>

                          <p className="text-sm text-gray">
                            {plant?.scentTypes}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {!_.isEmpty(plant?.commonNames) && (
                    <div className="bg-light-gray px-4 py-2 rounded-xl border border-gray-300">
                      <div className="flex flex-row gap-1 ">
                        <div className="flex flex-col">
                          <img
                            className="object-cover h-5 w-5"
                            src={"/icons/scent_icon.png"}
                            alt="scent_icon"
                          />
                        </div>
                        <div className="flex flex-col">
                          <p className="font-semibold text-sm">
                            {t("Common names")}
                          </p>

                          <p className="text-sm text-gray">
                            {typeof plant?.commonNames === "string"
                              ? plant?.commonNames
                              : plant?.commonNames.join(", ")}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* </div> */}
              </div>
              <div className="flex flex-col gap-2">
                <h2 className="text-2xl font-bold mb-2">
                  {t("Care Instructions")}
                </h2>
                <div className="bg-light-gray p-4 rounded-xl border border-gray-300">
                  <p className="text-gray text-base font-sfpro font-normal">
                    {plant?.careInstructions}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-light-green h-0.5 m-8" />

          <div className="flex flex-col justify-center items-center mt-5">
            <p className="text-gray text-base font-sfpro ml-5 font-medium">
              {t("Suggest edits to improve this plant information.")}
            </p>
            <MailtoExpert
              email="experts@odlamer.com"
              subject="Suggestion to improve plant information"
              body={`Plant Id: ${plant.id}\nPlant Name: ${plant.plantName}\nData Source: ${plant.dataSource}`}
            >
              <p className="text-gray font-bold text-base font-sfpro ml-5 underline">
                {t("Improve this plant data.")}
              </p>
            </MailtoExpert>
          </div>

          <div className="flex flex-col md:flex-row m-8 gap-8 justify-between">
            <div className="flex flex-col md:w-1/2">
              <p className="text-black text-2xl font-sfpro font-bold max-w-350">
                {t("The Odla Mer app is now available")}{" "}
                <span className="text-gray">
                  {t("for download on both iOS and Android")}
                </span>
              </p>
              <p className="text-gray text-md font-sfpro font-normal my-4 max-w-350">
                {t(
                  "In 🇸🇪🇳🇴🇫🇮 🇬🇧 🇳🇱, we are live on Apple app store and Google play store. Just now we are offering 60 days free premium trail to all our new users!"
                )}
              </p>
            </div>

            <div className="flex flex-row items-center gap-2">
              <div className="flex flex-col justify-center">
                {/* <p className="text-gray text-sm font-sfpro font-medium pb-3">
                  for IOS
                </p> */}
                <Link
                  to={"https://apps.apple.com/se/app/odla-mer/id1611168111"}
                  target="_blank"
                >
                  <img
                    className="object-cover h-10"
                    src={"/icons/app_store_icon.svg"}
                    alt="apple_store_badge"
                  />
                </Link>
              </div>
              <div className="flex flex-col justify-center">
                {/* <p className="text-gray text-sm font-sfpro font-medium pl-3 pb-3">
                  for Android
                </p> */}
                <Link
                  to={
                    "https://play.google.com/store/apps/details?id=com.odlamer.odlamerapp"
                  }
                  target="_blank"
                >
                  <img
                    className="object-cover h-10"
                    width={150}
                    src={"/icons/google-play-badge.png"}
                    alt="play_store_badge"
                  />
                </Link>
              </div>
            </div>
          </div>

          <Footer businessName={businessName} />
        </>
      )}
    </div>
  );

  return (
    <div className="flex">
      <div className="lg:w-6/12 md:w-8/12 sm:w-10/12 xs:w-12/12 mx-auto rounded-xl shadow-md overflow-hidden md:mt-10 sm:mt-5 xs:mt-2">
        {loading ? renderLoading() : renderPlantInfo()}
      </div>
    </div>
  );
};

export default Plant;
