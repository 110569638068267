import logo from "../assets/logo.png";
import "./HomePage.css";
function HomePage() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Odla Mer landing page is under construction. <br /> Please check back
          later.
        </p>
      </header>
    </div>
  );
}

export default HomePage;
