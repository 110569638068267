import { useTranslation } from 'react-i18next';
const Footer = ({ businessName }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col md:flex-row justify-center items-center my-16">
      <p className="text-gray text-md font-roboto text-center md:mr-2 md:mb-0 mb-2">
        Powered by
      </p>
      <img
        className="object-cover flex-shrink-0"
        width={100}
        height={28}
        src={'/icons/logo-with-text.png'}
        alt="logo with text"
      />
      {businessName === 'Hornbach' && (
        <>
          <p className="text-gray text-md text-center md:mx-2 md:mb-0 mb-2">
            {t('in collaboration with')}
          </p>
          <img
            className="object-cover flex-shrink-0"
            width={100}
            height={28}
            src={'/icons/hornbach_logo.png'}
            alt="logo with text"
          />
        </>
      )}
    </div>
  );
};

export default Footer;
